import Route from '@ember/routing/route';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
export default Route.extend({
  session: service(),
  model(params) {
    return this.get('loadData').perform(params.teamseason_id);
  },

  loadData: task(function* (teamseasonId) {
    let teamSeason = yield this.store.find('bask-team-season', teamseasonId);
    if (teamSeason) {
      let team = yield teamSeason.get('team');
      let color = yield team.get('awayColor');
      let homeGames=yield teamSeason.get('homeGames');
      let awayGames=yield teamSeason.get('awayGames');
      let games=[];
      homeGames.forEach((u) => {
        games.push(u);
      });
      awayGames.forEach((u) => {
        games.push(u);
      });
      return {
        games,
        teamSeason,
        team,
        color
      };
    }
  })
});
