import Service from '@ember/service';
import { run } from '@ember/runloop';
import { Promise as EmberPromise } from 'rsvp';
import { task } from 'ember-concurrency';
export default Service.extend({

  init() {
    this._super(...arguments);
  },
  load(needs){
    if (needs)
    {
      return this.get('_load').perform(needs);
    }
  },
  loadVideo() {
    return this.get('_loadVideo').perform();
  },
  _load:task (function* (needs){
    for (var v=0;v<needs.length;v++)
      {
        switch (needs[v])
        {
          case 'video': yield this.loadVideo(); break;
        }
      }
  }), 
  _loadVideo: task(function* () {
    yield loadCSS("https://vjs.zencdn.net/7.2.3/video-js.min.css"); //eslint-disable-line
    yield loadScript("https://vjs.zencdn.net/7.2.3/video.min.js");
  }),
});
let loadedScripts = {};

function loadScript(url) {
  let promise = loadedScripts[url];
  if (!promise) {
    promise = new EmberPromise((resolve, reject) => {
      let element = document.createElement('script');
      element.type = 'text/javascript';
      element.async = false;
      element.addEventListener('load', () => {
        run(() => {
          resolve();
        });
      }, false);
      element.addEventListener('error', () => {
        let error = new Error(`Could not load script ${url}`);
        run(() => {
          reject(error);
        });
      }, false);

      element.src = url;

      let firstScript = document.getElementsByTagName('script')[0];
      firstScript.parentNode.insertBefore(element, firstScript);
    });

    loadedScripts[url] = promise;
  }

  return promise;
}
