define("stattracker-public/helpers/per", ["exports", "stattracker-shared/helpers/per"], function (_exports, _per) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _per.default;
    }
  });
});
