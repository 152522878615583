define("stattracker-public/components/time-line", ["exports", "stattracker-shared/components/time-line"], function (_exports, _timeLine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _timeLine.default;
    }
  });
});
