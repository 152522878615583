import { task } from 'ember-concurrency';
import Route from '@ember/routing/route';
import cmMixin from 'stattracker-shared/mixins/color-manipulation';  
import { isPresent, isEmpty } from '@ember/utils';
export default Route.extend(cmMixin, {
  model(params) {
    let reportId = params['game-report_id'];
    if (isPresent(reportId)) {
      return this.get('loadData').perform(reportId);
    }
    this.transitionTo('index.index');
  },
  loadGameReportTask: task(function* (reportId) {
    let gameReport = yield this.store.find('game-report', reportId);
    let pbpReport = yield this.store.find('play-by-play-report', reportId);
    let awayTeamSeasonId = gameReport.get('boxReport.awayTeamSeasonId');
    let homeTeamSeasonId = gameReport.get('boxReport.homeTeamSeasonId');
    if (isEmpty(awayTeamSeasonId)||isEmpty(homeTeamSeasonId))
    {
      let game = yield this.store.find('bask-game', reportId);
      awayTeamSeasonId = game.belongsTo('awayTeam').id();
      homeTeamSeasonId = game.belongsTo('homeTeam').id();
    }
    let awayTeamSeason = yield this.store.find('bask-team-season', awayTeamSeasonId);
    let homeTeamSeason = yield this.store.find('bask-team-season', homeTeamSeasonId);
    let homeTeam = yield homeTeamSeason.get('team');
    let awayTeam = yield awayTeamSeason.get('team');
    let awayColor = '#' + awayTeam.get('awayColor');
    let homeColor = '#' + homeTeam.get('homeColor');
    gameReport.setProperties({
      homeTeam,
      awayTeam,
      awayTeamSeason,
      homeTeamSeason,
      awayColor,
      homeColor,
      playByPlay: pbpReport
    });
    return gameReport;
  }),
  loadData: task(function* (reportId) {
      // Need: homeTeamSeason & awayTeamSeason & homeColor & awayColor & boxReport
      let gameReportTask = this.get('loadGameReportTask').perform(reportId);
      let game = yield this.store.find('bask-game', reportId);
      let awayTeamSeasonId = game.belongsTo('awayTeam').id();
      let homeTeamSeasonId = game.belongsTo('homeTeam').id();
      let awayTeamSeason = yield this.store.find('bask-team-season', awayTeamSeasonId);
      let homeTeamSeason = yield this.store.find('bask-team-season', homeTeamSeasonId);
      let homeTeam = yield homeTeamSeason.get('team');
      let awayTeam = yield awayTeamSeason.get('team');
      let awayColor = '#' + awayTeam.get('awayColor');
      let homeColor = '#' + homeTeam.get('homeColor');
      let homeSecondColor = this.pSBC((homeColor === '#FFFFFF'?-.05:.25),homeColor);
      let awaySecondColor = this.pSBC((awayColor === '#FFFFFF'?-.05:.25),awayColor);
      document.body.style.setProperty('--home-primary-color', homeColor);
      document.body.style.setProperty('--away-primary-color', awayColor);
      document.body.style.setProperty('--home-secondary-color', homeSecondColor);
      document.body.style.setProperty('--away-secondary-color', awaySecondColor);
      document.body.style.setProperty('--home-text-color', this.getTextColor(homeColor));
      document.body.style.setProperty('--away-text-color', this.getTextColor(awayColor));
   return {
        gameReportTask: {data:gameReportTask},
        homeTeam,
        awayTeam,
        awayTeamSeason,
        homeTeamSeason,
        awayColor,
        homeColor,
        game
      };
  }),
  actions: {
    willTransition(transition){
      // if the transition is not in the same route
      if (transition.targetName.indexOf(this.fullRouteName) != 0) {
        document.body.style.removeProperty('--home-primary-color');
        document.body.style.removeProperty('--away-primary-color');
        document.body.style.removeProperty('--home-secondary-color');
        document.body.style.removeProperty('--away-secondary-color');
        document.body.style.removeProperty('--home-text-color');
        document.body.style.removeProperty('--away-text-color');
      }
    }
  }
});
