import liveData from 'stattracker-shared/mixins/live-data';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
export default Controller.extend(liveData, {
  init() {
    this._super();
  },
  session: service(),
  actions: {
    transition(location, data) {
      this.transitionToRoute(location, data);
    }
  }
});
