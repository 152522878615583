import EmberRouter from '@ember/routing/router';
import config from './config/environment';
import { inject as service } from '@ember/service';
import { scheduleOnce } from '@ember/runloop';
import { get } from '@ember/object';

const Router = EmberRouter.extend({
  location: config.locationType,
  rootURL: config.rootURL,
  metrics: service(),
  fastboot: service(),
  slug: 'kff',

  didTransition() {
    this._super(...arguments);
    this._trackPage();
  },
  // willTransition(oldInfos, newInfos, transition) {
    
  // },
  _trackPage() {
    if (!this.get('fastboot.isFastBoot')) {
      scheduleOnce('afterRender', this, () => {
        let page = document.location.pathname;
        let title = this.getWithDefault('currentRouteName', 'unknown');

        get(this, 'metrics').trackPage({ page, title });
      });
    }
  }
});
Router.map(function() {
  this.route('root', { path: '/'});
  this.route('index', { path: '/:slug'}, function() {
    this.route('game', { path: 'game/:bask-game_id' }, function() {
      this.route('live');
    });
    this.route('teamseason', { path: 'teamseason/:teamseason_id' }, function() {
      this.route('games');
    });
    this.route('report', { path: 'report/:game-report_id' }, function() {
      this.route('boxscore');
      this.route('play-by-play');
      this.route('visuals', function() {
        this.route('home');
        this.route('away');
      });
      this.route('downloads');
    });
    this.route('schedule');
    this.route('standings');
    this.route('video', { path: 'video/:video_id'});
  });
  this.route('catch-all', { path: '*:' });
  this.route('dashboard');
});
export default Router;
