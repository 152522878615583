import { task } from 'ember-concurrency';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
export default Route.extend({
  session: service(),

  model() {
    let active = this.get('session.activeSeasonId');
    if (active) {
      return this.get('loadData').perform(active);
    } else {
      return {
        standings: {}
      };
    }
  },

  loadData: task(function* (active) {
    let standings = yield this.store.query('bask-standing', {
      orderBy: 'season',
      equalTo: active
    });
    let season=yield this.store.find('bask-season',active);
    return {
      standings,
      season
    };
  }),
  setupController(controller, model) {
    controller.set('season', model.season);
    this._super(controller, model);
  }
});
