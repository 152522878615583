define("stattracker-public/division-data", ["exports", "statracker-shared/division-data"], function (_exports, _divisionData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "courtData", {
    enumerable: true,
    get: function () {
      return _divisionData.courtData;
    }
  });
  Object.defineProperty(_exports, "gameData", {
    enumerable: true,
    get: function () {
      return _divisionData.gameData;
    }
  });
});
