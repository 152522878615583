import Route from '@ember/routing/route';
export default Route.extend({
  model() {
    return this.modelFor('index.teamseason');
  },
  setupController(controller, model) {
    controller.set('teamSeason', model.teamSeason.id);
    this._super(controller, model);
  },
});
