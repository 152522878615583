import { filterBy, filter } from '@ember/object/computed';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';

export default Controller.extend( {
  queryParams: ['destination'],
  init () {
    this._super(...arguments);
    this.setup= {
      playbackRates: [0.5, 1, 1.5, 2],
      autoplay: false,
      controls: true,
      fluid: true,
      hls: {
        withCredentials: true
      }
    };
  },
  actions: {
    ready(player, component) {
      // This is pretty much the minimum required for a useful video player.
      component.bindPropertyToPlayer(player, 'src');
    }
  },
  session: service(),
  courtName: 'USHS',
  courtNumber: 0,
  fieldGoals: filterBy('model.events', 'type0', 0),
  shots: filter('fieldGoals', (shot) => shot.get('type1') > 1),

  homePlayers: computed('model.{homePlayers.[],playerGames.[]}', function() {
    let p = this.get('model.homePlayers');
    let pg = this.get('model.playerGames');
    return p.forEach((item) => {
      return item.set('playerGame', pg.findBy('playerSeason', item.id));
    });
  }),

  awayPlayers: computed('model.{awayPlayers.[],playerGames.[]}', function() {
    let p = this.get('model.awayPlayers');
    let pg = this.get('model.playerGames');
    return p.forEach((item) => {
      return item.set('playerGame', pg.findBy('playerSeason', item.id));
    });
  }),
  rawFilm: computed.alias('model.game.rawFilm'),
  rawFilmProcessing: computed('model.game.{rawFilm,rawFilmStatus}', function() {
    let film = this.get('model.game.rawFilm');
    if (film)
    {
      return this.get('model.game.rawFilmStatus') === 'processing';
    }
    return false;
  })
});
