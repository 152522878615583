import groupBy from 'stattracker-shared/utils/group-by';
import Route from '@ember/routing/route';
import { task } from 'ember-concurrency';
import { isPresent } from '@ember/utils';
export default Route.extend({
  model(params) { //eslint-disable-line no-unused-vars
    let reportId =  this.paramsFor('index/report')['game-report_id'];
    if (isPresent(reportId)) {
      return this.get('loadData').perform(reportId);
    }
  },
  loadData: task(function* (reportId) {
    let playByPlay = undefined;
    try {
      playByPlay = yield this.store.find('play-by-play-report', reportId);
    } catch (err) {
      if (err.code !== 'storage/object-not-found')
      {
        console.log(err); //eslint-disable-line
      }
    }
    return playByPlay;
  }),
  setupController(controller, model) {
    if (model && model.get('entities.length')) {
      model.set('entities', groupBy(model.get('entities'), 'pd'));
    }
    this._super(controller, model);
  }
});
