import Route from '@ember/routing/route';
import { task } from 'ember-concurrency';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import hd from 'stattracker-shared/mixins/hd';
export default Route.extend(hd, {
  headTags() {
    let model = this.modelFor(this.routeName);
    return this.buildTags({
      description: model.get('name'),
      title: 'Stattracker Live Video'
    });
  },
  lazyload: service(),
  beforeModel() {
    return this.get('lazyload').loadVideo();
  },
  model(params) {
    let videoId = params['video_id'];
    if (isPresent(videoId)) {
      return this.get('loadData').perform(videoId);
    }
    return undefined;
  },
  loadData: task(function* (videoId) {
    let video = yield this.store.find('video', videoId);
    return video;
  }),
});
