define("stattracker-public/components/vk-share-button", ["exports", "ember-social-share/components/vk-share-button"], function (_exports, _vkShareButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _vkShareButton.default;
    }
  });
});
