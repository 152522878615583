import { inject as service } from '@ember/service';
import liveData from '../../../mixins/live-data';
import Controller from '@ember/controller';
import { computed } from '@ember/object';
import EmObject from '@ember/object';
export default Controller.extend(liveData, {
  session: service(),
  init() {
    this._super();
    this.set('games',this.store.peekAll('bask-game'));
  },
  entry: computed('model.team', function() {
    return EmObject.create({
      selected:false,
      team:this.get('model.team'),
      awayColor: `#${this.get('model.team.awayColor')}`,
      id:this.get('model.team.id')
    });
  })
});