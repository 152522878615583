import ENV from '../config/environment';
import Service from '@ember/service';
import fetch from 'fetch';

export default Service.extend({
  lookup({slug})  {
    let url = `${ENV.ffApi}/lookup?slug=${slug}`;
    return this.request(url);
  },

  token({account, uid}, t)  {
    let url = `${ENV.ffApi}/token?account=${account}&uid=${uid}`;
    return this.request(url, t);
  },

  validate({id})  {
    let url = `${ENV.ffApi}/validate?id=${id}`;
    return this.request(url);
  },

  request(url, token) {
    let headers = {
      Accept: 'application/json'
    }
    if (token) {
      headers.Authorization = 'Bearer ' + token
    }
    return fetch(url, headers)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      });
  }
});
