define("stattracker-public/components/stat-input", ["exports", "stattracker-shared/components/stat-input"], function (_exports, _statInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _statInput.default;
    }
  });
});
