import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { equal } from '@ember/object/computed';
import { reject } from 'rsvp';
export default Service.extend({
  cookies: service(),
  firebaseApp: service(),
  api: service('st-api'),

  lookup(slug, remember) {
    let api = this.get('api');
    let auth = this.get('firebaseApp').auth();
    let cookies = this.get('cookies');
    if (api) {
      return api.lookup({ slug }).then((result) => {
        if (result && result.season && result.customToken) {
          return auth.signInWithCustomToken(result.customToken)
          .then(() => {
            this.setActiveReference(result, slug);
            if (remember) {
              let slugs = JSON.parse(cookies.read('slugs') || '{}');
              let { displayName, plan, reference, season } = result;
              slugs[displayName] = {
                displayName,
                plan,
                reference,
                season,
                slug
              };
              cookies.write('slugs', JSON.stringify(slugs), {maxAge: 31536000});
            }
          });
        }
        return reject('slug found but no season/token associated');
      });
    }
    return reject('no api');
  },

  setActiveReference(data, slug) {
    let {
      reference,
      owner,
      plan,
      publicReports,
      publicVideo
    } = data;
    this.setProperties({
      accountId: data.account,
      activeSeasonId: data.season,
      teamSeasonId: data.teamSeason,
      reference,
      owner,
      plan,
      publicReports,
      publicVideo,
      slug,
      isPublic: true,
      userStatus: 0
    });
  },
  setActiveSeasonId(season) {
    this.set('activeSeasonId', season);
  },
  isLeague: equal('plan', 'league'),
  isTeam: equal('plan', 'team')
});
