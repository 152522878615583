define("stattracker-public/components/box-score", ["exports", "stattracker-shared/components/box-score"], function (_exports, _boxScore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _boxScore.default;
    }
  });
});
