define("stattracker-public/components/base-d3-component", ["exports", "stattracker-shared/components/base-d3-component"], function (_exports, _baseD3Component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _baseD3Component.default;
    }
  });
});
