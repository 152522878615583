import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default Controller.extend({
  queryParams: ['utm_source', 'utm_medium', 'utm_campaign'],
  cookies: service(),
  api: service('st-api'),
  actions: {
    search(slug) {
      let api = this.get('api');
      api.lookup({ slug }).then((result) => {
        result.slug = slug;
        this.setProperties({
          result,
          error: false
        })
      }).catch(() => {
        this.setProperties({
          result: null,
          error: true
        });
      });
    },

    remember(page){
      let cookies = this.get('cookies');
      let slugs = JSON.parse(cookies.read('slugs') || '{}');
      let { displayName, plan, reference, season, slug } = page;
      slugs[displayName] = {
        displayName,
        plan,
        reference,
        season,
        slug
      };
      this.set('model', slugs);
      cookies.write('slugs', JSON.stringify(slugs), {maxAge: 31536000});
      this.set('query', '');
      this.set('result', null);
    },
    
    // remove(name){
    //   let cookies = this.get('cookies');
    //   let slugs = JSON.parse(cookies.read('slugs') || '{}');
    //   delete slugs[name];
    //   this.set('model', slugs);
    //   cookies.write('slugs', JSON.stringify(slugs), {maxAge: 31536000});
    // }
  }
});
