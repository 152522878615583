define("stattracker-public/components/court-tracker", ["exports", "stattracker-shared/components/court-tracker"], function (_exports, _courtTracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _courtTracker.default;
    }
  });
});
