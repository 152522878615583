import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';

export default Controller.extend({
  cookies: service(),
  session: service(),
  remember: computed(function() {
    return this.get('cookies').read('rememberSlug') || true;
  }),
  
  actions: {
    submit(slug, remember) {
      if (remember) {
        this.get('cookies').write('rememberSlug', true);
      }
      let session = this.get('session');
      session.lookup(slug, remember).then(() => {
        this.transitionToRoute('index.index', slug);
      });
    }
  }
});