
import { computed } from '@ember/object';
import { alias, equal, filter, filterBy, and } from '@ember/object/computed';
import { htmlSafe } from '@ember/string';
import Controller from '@ember/controller';
import { bind } from '@ember/runloop';
import EmObject from '@ember/object';
import { inject as service } from '@ember/service';
import { per } from 'stattracker-public/helpers/per';
export default Controller.extend({
  metrics: service(),
  session: service(),
  intl: service(),
  rate: 1,
  courtName: 'USHS',
  courtNumber: 0,
  isLive: equal('model.game.status', 'live'),
  canTrack: and('isLive', 'model.game.isValid'),
  courtType: alias('model.game.courtType'),
  fieldGoals: filterBy('model.eventLedger.events', 'type0', 0),
  shots: filter('fieldGoals', (shot) => shot.get('type1') > 1),
  events: computed('model.eventLedger.events', function () {
    return this.get('model.eventLedger.events').toArray().reverse();
  }),
  teamMouseCallback: computed(function (entry) { //eslint-disable-line no-unused-vars
    return bind(this, function (entry) { //eslint-disable-line no-unused-vars
      // var homeTeamId=this.get('model.homeTeam.id');
      // if (entry.get('id')===homeTeamId)
      // {
      //   this.set('awayTeamEntry.selected', false); 
      // }
      // else{
      //   this.set('homeTeamEntry.selected', false); 
      // }
    });
  }),
  homeTeamEntry: computed('model.homeTeam', function () {
    return EmObject.create({
      selected: false,
      team: this.get('model.homeTeam'),
      homeColor: htmlSafe(`#${this.get('model.homeTeam.homeColor')}`),
      id: this.get('model.homeTeam.id')
    });
  }),
  awayTeamEntry: computed('model.awayTeam', function () {
    return EmObject.create({
      selected: false,
      team: this.get('model.awayTeam'),
      awayColor: htmlSafe(`#${this.get('model.awayTeam.awayColor')}`),
      id: this.get('model.awayTeam.id')
    });
  }),
  homePlayers: computed('model.{homePlayers.[],playerGames.[]}', function () {
    let p = this.get('model.homePlayers');
    let pg = this.get('model.playerGames');
    return this.setupPlayers(p, pg);
  }),
  inGameHomePlayers: filterBy('homePlayers', 'isInGame', true),
  benchHomePlayers: filterBy('homePlayers', 'isInGame', false),
  setupPlayers: (players, playerGames) => {
    players.forEach((player) => {
      var playerGame = playerGames.findBy('playerSeason', player.id);
      if (playerGame) {
        player.setProperties({
          isHome: false,
          selected: false,
          playerGame,
        });
      }
    });
    return players;
  },
  awayPlayers: computed('model.{awayPlayers.[],playerGames.[]}', function () {
    let p = this.get('model.awayPlayers');
    let pg = this.get('model.playerGames');
    return this.setupPlayers(p, pg);
  }),
  inGameAwayPlayers: filterBy('awayPlayers', 'isInGame', true),
  benchAwayPlayers: filterBy('awayPlayers', 'isInGame', false),
  actions: {
    navigate() {
      this.transitionToRoute('index');
    },
    share() {
      // x leads y with {{time}} left in the {{period}}
      // follow the game on Stattracker Live
      if (navigator.share) {
        let game = this.get('model.game');
        let text, main, secondary, descriptor, winningScore, losingScore;
        if (this.get('session.teamSeasonId') == this.get('model.awayTeamSeason.id')) {
          main = 'away';
          secondary = 'home';
        } else {
          main = 'home';
          secondary = 'away'
        }
        let margin = Math.abs(game.get('homeScore') - game.get('awayScore'));
        let timeLeft = `with ${game.get('timeLeftInPeriod')} remaining in the ${this.get('intl').t(per([game.get('periodCurrent'), game.get('periodCount')]))}`;
        let mainName = this.get(`model.${main}Team.name`);
        let secondaryName = this.get(`model.${secondary}Team.name`);
        if (game.get(main + 'Winning')) {
          descriptor = 'ahead';
          winningScore = game.get(main + 'Score');
          losingScore = game.get(secondary + 'Score');
        } else {
          descriptor = 'behind';
          winningScore = game.get(secondary + 'Score');
          losingScore = game.get(main + 'Score');
        }
        if (margin === 0) {
          text = `${mainName} and ${secondaryName} tied at ${winningScore} ${timeLeft}`;
        } else {
          text = `${mainName} ${descriptor} by ${margin} against ${secondaryName} (${winningScore} - ${losingScore}) ${timeLeft}`;
        }
      
        // share takes an object containing title, text, and url of what to share
        this.get('metrics').trackEvent('GoogleAnalytics', {
          category: 'share',
          action: 'share',
          label: 'Web Share API'
        });
        navigator.share({
          title: 'Follow in real-time using Stattracker Live',
          text,
          url: window.location.href
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
      } else {
        console.log('Web Share API not supported');
      }
    }
  }
});
