import Route from '@ember/routing/route';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
export default Route.extend({
  session: service(),

  model() {
    let active = this.get('session.activeSeasonId');
    if (active) {
      return this.get('loadData').perform(active);
    } else {
      return {
        games: {}
      };
    }
  },

  loadData: task(function* (active) {
    let reference = this.get('session.reference');
    let games = yield this.store.query('bask-game', {
      orderBy: 'season',
      equalTo: active
    });
    let season = yield this.store.find('bask-season', active);

    return {
      reference,
      games,
      season
    };
  }),
  setupController(controller, model) {
    controller.set('season', model.season);
    controller.set('teamSeason', this.get('session.teamSeasonId'));
    controller.set('games', model.games);
    this._super(controller, model);
  }
});
