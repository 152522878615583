import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
export default Controller.extend({
  session: service(),
  shared: computed('session.publicVideo', 'model.share', function(){
    let publicVideo = this.get('session.publicVideo');
    let shared = this.get('model.share');
    return publicVideo || shared;
  }),
  init () {
    this._super(...arguments);
    this.setup= {
      playbackRates: [0.5, 1, 2],
      autoplay: false,
      controls: true,
      fluid: true,
      hls: {
        withCredentials: true
      }
    };
  },
  actions: {
    ready(player, component) {
      // This is pretty much the minimum required for a useful video player.
      component.bindPropertyToPlayer(player, 'src');
    }
  },
});
