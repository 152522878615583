import { task } from 'ember-concurrency';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { filterBy, filter } from '@ember/object/computed';
/* global moment*/

export default Route.extend({
  firebaseApp: service(),
  session: service(),
  beforeModel() {
    let { slug } = this.paramsFor('index');
    let session = this.get('session');
    var active = session.get('activeSeasonId');
    if (active && session.get('slug') === slug) {
      return;
    } else if (slug) {
      return session.lookup(slug);
    } else {
      return this.transitionTo('index');
    }
  },
  model() {
    var active = this.get('session.activeSeasonId');
    var ts = this.get('session.teamSeasonId');
    let store = this.get('store');
    return {
      data: this.get('loadData').perform(store, active, ts) 
    };
  },

  loadData: task({
    scheduledGames: filterBy('value', 'status', 'scheduled'),
    liveGames: filterBy('value', 'status', 'live'),
    finalGames: filter('value', function(game, index, array) { //eslint-disable-line no-unused-vars
      let status = game.get('status');
      return status === 'finished' || status === 'edited' || status === 'reporting';
    }),
    upcomingGames: filter('scheduledGames', (game) => {
      let now = moment();
      let nextWeek = moment().add(7, 'days');
      return game.get('date').isBetween(now, nextWeek);
    }),
    *perform(store, active, teamSeason) {
      let games = yield store.query('bask-game', {
        orderBy: 'season',
        equalTo: active
      });
      if (teamSeason) {
        games = games.filter(function (item) {
          return [item.get('homeTeam.id'), item.get('awayTeam.id')].includes(teamSeason);
        });
      }
      return games.filterBy('isValid', true).sortBy('sortableDate').reverse();
    }
  }),

  setupController(controller, model) {
    controller.set('season', model.season);
    controller.set('games', model.games);
    controller.set('teamSeason', this.get('session.teamSeasonId'));
    this._super(controller, model);
  },

  renderTemplate() {
    this.render(`index.dashboard`);
  }
});
