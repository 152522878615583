
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import ENV from '../config/environment';
import hd from 'stattracker-shared/mixins/hd';
export default Route.extend(hd, {
  intl: service(),
  session: service(),
  firebaseApp: service(),
  cookies: service(),
  headTags() {
    return this.buildTags(ENV.meta);
  },
  beforeModel(transition) {
    this.get('intl').setLocale('en-us');
    var slug;
    if (transition && transition.params && transition.params.index) {
      slug = transition.params.index.slug;
      console.log('looking up slug', slug);
      return this.get('session').lookup(slug, false);
    }
  },

  actions: {
    error(error/*, transition*/) {
      console.error(error);
      this.transitionTo('index');
    }
  }
});