define("stattracker-public/mixins/player-career-visual-mixin", ["exports", "stattracker-shared/mixins/player-career-visual-mixin"], function (_exports, _playerCareerVisualMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _playerCareerVisualMixin.default;
    }
  });
});
