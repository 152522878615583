define("stattracker-public/serializers/game-event-ledger", ["exports", "stattracker-shared/serializers/game-event-ledger"], function (_exports, _gameEventLedger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _gameEventLedger.default;
    }
  });
});
